<template>
  <div class="container-recommendedItemall">
    <div class="auctionhome-header">
      <div class="auctionhome-header-left">
        <span :class="{'tabSelect':tabIndex==index}" v-for="(item,index) in tabArr" :key="index" @click="tabSelect(index)">{{item}}</span>
        <p v-if="tabIndex==0">{{total}}个拍品</p>
      </div>
      <div id="components-pagination-demo-mini">
        <a-pagination size="small" :total="total" @change="onChange" :pageSize='searchData.pageSize'
          v-model='searchData.page' />
      </div>
      <div class="auctionhome-header-right">
        <span>每页显示</span>
        <div class="pagination-middle">
          <a-select placeholder="40" style="width: 120px" v-model="value2" @change="handleChange">
            <a-select-option :value="item.num" v-for="(item,index) in pageSizeNum" :key="index">
              {{item.num}}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="search-list">

        <!-- 左侧组件 -->
        <RecommendedItemchoose v-on:emitData='showMsgfromChild' :attributeListA='attributeListA'
          :brandAuthorIdA='brandAuthorIdA' :stylePeriodIdA='stylePeriodIdA' :materialsCraftIdA='materialsCraftIdA'
          :placeSourceIdA='placeSourceIdA' :auctionHouseIdA='auctionHouseIdA' :classifyIdA='classifyIdA'
          :twoClassifyIdB='twoClassifyIdB' :auctionWayIdA='auctionWayIdA' v-show="tabIndex==0"/>
        <!-- 右侧组件 -->
        <RecommendedIteright :searchDataList='searchDataList' :page='searchData.page' :pageSize='searchData.pageSize' v-show="tabIndex==0"/>


      <HouseRight :houseList='houseList' :page='searchData.page' :pageSize='searchData.pageSize' @refresh="houserefresh" v-show="tabIndex==1"></HouseRight>
    </div>
    <div class="auctionhome-header-b">
      <div class="auctionhome-header-left">
        <span :class="{'tabSelect':tabIndex==index}" v-for="(item,index) in tabArr" :key="index" @click="tabIndex=index">{{item}}</span>
        <p v-if="tabIndex==0">{{total}}个拍品</p>
      </div>
      <div id="components-pagination-demo-mini" @click="goTop">
        <a-pagination size="small" :total="total" @change="onChange" :pageSize='searchData.pageSize'
          v-model='searchData.page' />
      </div>
      <div class="auctionhome-header-right">
        <span>每页显示</span>
        <div class="pagination-middle">
          <a-select placeholder="40" style="width: 120px" v-model="value2" @change="handleChange">
            <a-select-option :value="item.num" v-for="(item,index) in pageSizeNum" :key="index">
              {{item.num}}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import RecommendedItemchoose from './components/RecommendedItemchoose.vue'
import RecommendedIteright from './components/RecommendedIteright.vue'
import HouseRight from './components/HouseRight.vue'
import { search, searchAttribute } from '../../api/index'
export default {
  name: 'RecommendedItemall',
  components: {
    RecommendedItemchoose,
    RecommendedIteright,
    HouseRight
  },
  inject: ['reload'],
  data () {
    return {
      isChoose: false,
      value: '',
      searchData: {
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        content: '',
        type: 0,
        page: 1,
        pageSize: 40,
        classifyId: '',
        twoClassifyId: '',
        userId:Number(localStorage.getItem('accessId')) || 0
      },
      attributeListA: {},
      brandAuthorIdA: {},
      stylePeriodIdA: {},
      materialsCraftIdA: {},
      placeSourceIdA: {},
      auctionHouseIdA: {},
      auctionWayIdA: {},
      classifyIdA: {},
      twoClassifyIdB: {},
      total: 0,
      searchDataList: [],
      pageSizeNum: [
        {
          id: 1,
          num: 40
        },
        {
          id: 2,
          num: 80
        },
        {
          id: 3,
          num: 120
        }
      ],
      value2: 40,
      tabIndex:0,
      tabArr:['拍品','拍卖行'],
      houseList:[]
    }
  },
  created () {
    this.searchData.content = this.$route.query.content
    if (sessionStorage.getItem('msgInfo20')) {
      this.searchData.page = Number(sessionStorage.getItem('currentPage20')) || 1
      this.searchData.pageSize = Number(sessionStorage.getItem('select20')) || 40
      this.value2 = Number(sessionStorage.getItem('select20')) || 40
    } else {
      sessionStorage.removeItem('currentPage20')
      sessionStorage.removeItem('select20')
      this.searchData.page = 1
      this.searchData.pageSize = 40
      this.value2 = 40
    }
    this.search()
    this.searchAttribute()
  },
  watch: {
    $route () {
      this.searchData.page = 1
      this.searchData.content = this.$route.query.content
      this.search()
      this.searchHouse()
      this.searchAttribute()
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed () {
    this.reload()
    sessionStorage.removeItem('msgInfo20')
  },
  methods: {
    tabSelect(index){
      if(this.tabIndex==index) return
      this.tabIndex=index
      this.searchData.page=1
      if(this.tabIndex==1){
        this.searchHouse()
      }else{
        this.search()
      }
    },
    toggePay () {
      this.isChoose = !this.isChooses
    },
    handleChange (value) {
      this.searchData.pageSize = value
      if(this.tabIndex==1){
        this.searchHouse()
      }else{
        this.search()
      }
    },
    handleScroll (e) {
      const scrolltop = e.target.scrollTop
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false)
    },
    goTop () {
      let top = (document.documentElement.scrollTop - 300) || (document.body.scrollTop - 300)
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 150
        if (top <= 300) {
          clearInterval(timeTop)
        }
      }, 0)
    },
    // 子组件的参数
    showMsgfromChild (data) {
      this.searchData.auctionWayId = data.auctionWayId
      this.searchData.brandAuthorId = data.brandAuthorId
      this.searchData.stylePeriodId = data.stylePeriodId
      this.searchData.materialsCraftId = data.materialsCraftId
      this.searchData.placeSourceId = data.placeSourceId
      this.searchData.auctionHouseId = data.auctionHouseId
      this.searchData.classifyId = data.classifyId
      this.searchData.twoClassifyId = data.twoClassifyId
      this.searchData.page = data.page
      this.search()
    },
    async search () {
      this.searchData.type=0
      const res = await search(this.searchData)
      if (res.code === 0) {
        this.searchDataList = res.data.itemList||[]

        this.total = res.data.count
      } else {
        this.$message.error(res.msg)
      }
    },
    async searchHouse () {
      this.searchData.type=1
      const res = await search(this.searchData)
      if (res.code === 0) {
        this.houseList = res.data.auctionHouseList||[]
        this.total = res.data.count
      } else {
        this.$message.error(res.msg)
      }
    },
    houserefresh(){
      console.log('刷新');
      this.searchData.page=1
      this.searchHouse()
    },
    async searchAttribute () {
      const res = await searchAttribute({
        content: this.$route.query.content
      })
      if (res.code === 0) {
        this.classifyIdA = res.data.list[0] // 类别
        this.auctionWayIdA = res.data.list[1] // 拍卖形式
        this.brandAuthorIdA = res.data.list[2]// 品牌作者
        this.stylePeriodIdA = res.data.list[3]// 风格时期
        this.materialsCraftIdA = res.data.list[4] // 材料工艺
        this.placeSourceIdA = res.data.list[5] // 国家地区
        this.auctionHouseIdA = res.data.list[6]// 拍卖行

        // this.attributeListA = res.data.list[2]
      } else {
        this.$message.error(res.msg)
      }
    },
    onChange (page) {
      this.searchData.page = page
      sessionStorage.setItem('page4', this.searchData.page)
      if(this.tabIndex==1){
        this.searchHouse()
      }else{
        this.search()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container-recommendedItemall {
  /deep/ .ant-pagination-item-active {
    border: none;
  }
  #components-pagination-demo-mini .ant-pagination:not(:last-child) {
    margin-bottom: 24px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-select-selection__placeholder {
    color: rgba(0, 0, 0, 0.65);
  }
  /deep/ .ant-pagination-item-active {
    border: none;
  }
  #components-pagination-demo-mini .ant-pagination:not(:last-child) {
    margin-bottom: 24px;
  }
  /deep/ .ant-select {
    width: 80px !important;
  }
  /deep/ .ant-pagination-item-active a {
    color: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #cda156;
  }
  /deep/ .ant-pagination-item {
    font-size: 17px;
  }
  /deep/ .ant-pagination-item-active a {
    color: #fff;
    background: #cda156;
  }
  /deep/ .ant-pagination-item a:hover {
    color: #000;
  }
  /deep/ .ant-pagination-item {
    border: 1px solid #ccc !important;
    margin: 0px 5px;
    a {
      font-size: 15px;
    }
  }
  /deep/ .ant-pagination-prev {
    border: 1px solid #ccc !important;
    margin-right: 5px;
  }
  /deep/ .ant-pagination-next {
    border: 1px solid #ccc !important;
    margin-left: 5px;
  }
  /deep/ .anticon {
    vertical-align: 0;
  }
  /deep/ .ant-pagination-item-active {
    background: #cda156;
  }
  .auctionhome-header {
    height: 70px;
    align-items: center;
    display: flex;
    margin: 0 100px;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    // p:nth-child(1) {
    //   font-size: 20px;
    //   font-weight: 400;
    //   color: #333;
    //   margin-bottom: 0;
    // }
    .auctionhome-header-right {
      display: flex;
      align-items: center;

      span {
        font-size: 18.5px;
        color: #333;
        font-weight: 400;
        padding-right: 10px;
      }

      p:nth-child(2) {
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
          padding-right: 10px;
        }
        a {
          color: #333;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .auctionhome-header-left{
    display: flex;
    align-items: center;
    >span{
      width: 120px;
      height: 50px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #BEC3C6;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      cursor: pointer;
    }
    >.tabSelect{
      border: 1px solid #CDA156;
      font-weight: 600;
      color: #CDA156;
    }
    >p{
      font-size: 20px;
        font-weight: 500;
        color: #333;
        margin-bottom: 0;
        margin-left: 40px;
    }
  }
  .auctionhome-header-b {
    height: 70px;
    align-items: center;
    display: flex;
    margin: 0 100px;
    margin-top: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;

    // p:nth-child(1) {
    //   font-size: 20px;
    //   font-weight: 400;
    //   color: #333;
    //   margin-bottom: 0;
    // }
    .auctionhome-header-right {
      display: flex;
      align-items: center;

      span {
        font-size: 18.5px;
        color: #333;
        font-weight: 400;
        padding-right: 10px;
      }

      p:nth-child(2) {
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
          padding-right: 10px;
        }
        a {
          color: #333;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .search-list {
    display: flex;
  }
}
</style>
