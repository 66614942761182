<template>
  <div class="HouseRight">
    <div class="content" v-if="houseList.length>0">
      <div class="list" v-for="(item,index) in houseList" :key="index" @click="goDetails(item.id)">
        <img :src="item.logo" v-if="item.logo" @error="defaultImgs(index)" />
        <div>
          <div class="title">{{item.corporateName||item.name}}</div>
          <div class="collect" @click.stop="biddingCollot(item.id)">
            <!-- <img src="../../../assets/images/aixi16-14.png"/> -->
            <i class="iconfont aixin" :class="[item.userRemindStatus? 'icon-aixin' : 'icon-kongaixin']"></i>
            <span>{{item.userRemindStatus?'已收藏':'收藏'}}</span>
          </div>

        </div>
      </div>
    </div>
    <div class="search-emty" v-else>
      <img src="../../../assets/images/22.png" alt="">
      <span>此分类暂无数据！！</span>
    </div>
    <Login ref="login" />
  </div>
</template>
<script>
  import Login from '../../../components/login.vue'
  import {
    collectAuctionHouse
  } from '../../../api/index'
  export default {
    name: 'HouseRight',
    components: {
      Login
    },
    props: {
      houseList: {
        type: Array,
        default: () => {}
      },
    },
    data() {
      return {

      }
    },
    created() {

    },
    methods: {
      defaultImgs(index) {
        this.houseList[index].logo = ""
      },
      goDetails(id) {
        const routeUrl = this.$router.resolve({
          path: '/auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
        window.open(routeUrl.href, '_blank')
      },
      async biddingCollot(id) {
        console.log(id);
        if (!localStorage.getItem('accessId')) {
          this.$nextTick(() => {
            this.$refs.login.open()
          })
          return
        }
        const res = await collectAuctionHouse({
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          auctionHouseId: id
        })
        if (res.code === 0) {
          if (res.data.status === true) {
            this.$message.success('收藏成功')
          } else {
            this.$message.error('取消成功')
          }
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg)
        }
      },
    }
  }
</script>
<style lang="less" scoped>
  .HouseRight {
    width: 100%;

    >.content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 90px;

      >.list {
        width: 850px;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;

        >img {
          width: 200px;
          height: 120px;
          object-fit: contain;
        }

        >div {
          margin-left: 25px;

          >.title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
          }

          >.collect {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 24px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.7);
            border: 1px solid #CDA156;
            cursor: pointer;

            // >img{
            //   width: 16px;
            //   height: 14px;
            // }
            >.aixin {
              color: rgb(234, 49, 74);
              font-size: 13px;
            }

            >span {
              font-size: 14px;
              font-weight: 400;
              color: #CDA156;
              margin-left: 4px;
            }
          }
        }
      }
    }

    >.search-emty {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 69px;

      img {
        width: 169px;
      }

      span {
        padding-top: 69px;
        color: #cda156;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
</style>
