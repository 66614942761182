<template>
  <div class="container-choose">
    <div class="choose-left">
      <div class="choose-emty">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">重置</el-checkbox>
      </div>
      <!-- <div class="choose-purchase" v-if="classifyIdA.valueAttribute !== null">
        <p>{{classifyIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesSeven" @change="handleCheckedCitiesChange($event,classifyIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in classifyIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div> -->
      <div class="choose-purchase" v-if="classifyIdA.valueAttribute !== null">
        <p>{{classifyIdA.name}} </p>
        <div class="checked">
          <!-- <el-checkbox-group v-model="checkedCitiesFive" @change="handleCheckedCitiesChange($event,classifyId.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in classifyIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group> -->
          <el-tree :data="classifyIdA.valueAttribute" :render-content='renderContent' ref="tree" show-checkbox
            node-key="id" @check='handleNodeClick' :default-checked-keys='checkedCitiesFive' :props="defaultProps">
          </el-tree>
        </div>
      </div>
      <div class="choose-purchase" v-if="auctionWayIdA.valueAttribute !== null">
        <p>{{auctionWayIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange($event,auctionWayIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in auctionWayIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <!-- <div class="choose-purchase" v-if="attributeListA.valueAttribute !== null">
        <p>{{attributeListA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange($event,attributeListA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in attributeListA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div> -->
      <div class="choose-purchase" v-if="brandAuthorIdA.valueAttribute !== null">
        <p>{{brandAuthorIdA.name}} </p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesTwo" @change="handleCheckedCitiesChange($event,brandAuthorIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in brandAuthorIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="stylePeriodIdA.valueAttribute !== null">
        <p>{{stylePeriodIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesSthree"
            @change="handleCheckedCitiesChange($event,stylePeriodIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in stylePeriodIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="materialsCraftIdA.valueAttribute !==null">
        <p>{{materialsCraftIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesFour"
            @change="handleCheckedCitiesChange($event,materialsCraftIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in materialsCraftIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="placeSourceIdA.valueAttribute !== null">
        <p>{{placeSourceIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesFive" @change="handleCheckedCitiesChange($event,placeSourceIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in placeSourceIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="choose-purchase" v-if="auctionHouseIdA.valueAttribute !== null">
        <p>{{auctionHouseIdA.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesSix" @change="handleCheckedCitiesChange($event,auctionHouseIdA.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in auctionHouseIdA.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>

      <!-- <div class="choose-purchase" v-if="twoClassifyIdB.valueAttribute !== null">
        <p>{{twoClassifyIdB.name}}</p>
        <div class="checked">
          <el-checkbox-group v-model="checkedCitiesEit" @change="handleCheckedCitiesChange($event,twoClassifyIdB.id)">
            <el-checkbox :label="item.id" v-for="(item,index) in twoClassifyIdB.valueAttribute" :key="index"
              :checked='checkAlle'>
              {{item.name}} </el-checkbox>
          </el-checkbox-group>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { attribute } from '../../../api/index'
export default {
  name: 'Choose',
  components: {

  },
  props: {
    attributeListA: {
      type: Object
    },
    brandAuthorIdA: {
      type: Object
    },
    stylePeriodIdA: {
      type: Object
    },
    materialsCraftIdA: {
      type: Object
    },
    placeSourceIdA: {
      type: Object
    },
    auctionHouseIdA: {
      type: Object
    },
    classifyIdA: {
      type: Object
    },
    twoClassifyIdB: {
      type: Object
    },
    auctionWayIdA: {
      type: Object
    }
  },
  inject: ['reload'],
  data () {
    return {
      attributeList: [],
      brandAuthorId: [],
      stylePeriodId: [],
      materialsCraftId: [],
      placeSourceId: [],
      auctionHouseId: [],
      checkAll: false,
      checkedCities: [],
      checkedCitiesTwo: [],
      checkedCitiesSthree: [],
      checkedCitiesFour: [],
      checkedCitiesFive: [],
      checkedCitiesSix: [],
      checkedCitiesSeven: [],
      checkedCitiesEit: [],
      isIndeterminate: true,
      checkAlle: false,
      emitData: {
        auctionWayId: '',
        brandAuthorId: '',
        stylePeriodId: '',
        materialsCraftId: '',
        placeSourceId: '',
        auctionHouseId: '',
        classifyId: '',
        twoClassifyId: '',
        page: 1
      },
      defaultProps: {
        children: 'valueAttribute',
        label: 'name'
      }
    }
  },
  created () {
    this.attribute()
  },
  methods: {
    handleCheckAllChange (val) {
      this.checkedCities = []
      this.checkedCitiesTwo = []
      this.checkedCitiesSthree = []
      this.checkedCitiesFour = []
      this.checkedCitiesFive = []
      this.emitData.auctionWayId = ''
      this.emitData.brandAuthorId = ''
      this.emitData.stylePeriodId = ''
      this.emitData.materialsCraftId = ''
      this.emitData.placeSourceId = ''
      this.emitData.auctionHouseId = ''
      this.emitData.classifyId = ''
      this.emitData.twoClassifyId = ''
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([])
      })
      this.$emit('emitData', this.emitData)
    },
    handleCheckedCitiesChange (value, id) {
      if (id === 1) {
        this.emitData.auctionWayId = value.toString()
      } else if (id === 2) {
        this.emitData.brandAuthorId = value.toString()
      } else if (id === 3) {
        this.emitData.stylePeriodId = value.toString()
      } else if (id === 4) {
        this.emitData.materialsCraftId = value.toString()
      } else if (id === 5) {
        this.emitData.placeSourceId = value.toString()
      } else if (id === 6) {
        this.emitData.auctionHouseId = value.toString()
      } else if (id === 101) {
        this.emitData.twoClassifyId = this.checkedCitiesEit.join(',')
      }
      this.$emit('emitData', this.emitData)
    },
    handleNodeClick (data) {
      this.emitData.classifyId = this.$refs.tree.getCheckedKeys().toString()
      this.$emit('emitData', this.emitData)
    },
    // 数据初始化列表
    async attribute () {
      const res = await attribute({
        id: 0
      })
      if (res.code === 0) {
        this.attributeList = res.data.list[0]
        this.brandAuthorId = res.data.list[1]
        this.stylePeriodId = res.data.list[2]
        this.materialsCraftId = res.data.list[3]
        this.placeSourceId = res.data.list[4]
        this.auctionHouseId = res.data.list[5]
      } else {
        this.$message.error(res.msg)
      }
    },
    // <span>({node.parent.data.count})</span>

    renderContent (h, { node, data, store }) {
      return (
        <span style='font-size:0.072917rem;color:#606266;font-weight:500'>
          <span>{node.label}&nbsp;</span>
        </span >
      )
    }

  }
}
</script>
<style lang="less" scoped>
.container-choose {
  width: 450px;
  display: flex;
  flex-direction: column;
  /deep/ .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #cda156;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #cda156;
    border-color: #cda156;
  }
  /deep/ .el-checkbox {
    margin-bottom: 10px;
  }
  /deep/ .el-checkbox__inner:hover {
    border-color: #cda156;
  }
  .choose-left {
    width: 420px;
  }
  .choose-emty {
    width: 280px;
    height: 44px;
    border: 1px solid #dedede;
    line-height: 44px;
    margin: 20px 0 30px 90px;
    font-weight: 400;
    /deep/ .el-checkbox__inner {
      display: none;
    }
    /deep/ .el-checkbox__label {
      color: #cda156;
    }
    a {
      color: #6e5121;
      font-size: 16px;
    }
  }
  .choose-purchase {
    display: flex;
    flex-direction: column;
    // align-items: self-end;
    margin-left: 90px;
    text-align: left;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
    max-height: 200px;
    p {
      margin-bottom: 10px;
    }
    p:nth-child(1) {
      font-weight: 600;
      font-size: 16px;
      color: #333333;
    }
    .checked {
      display: flex;
      flex-direction: column;
      // align-items: self-end;
      overflow: hidden;
      // height: 180px;
    }
    .checked:hover {
      white-space: nowrap; //阻止文本换行
      overflow: auto;
    }

    .choose-more {
      margin-top: 10px;
      width: 370px;
      text-align: left;
      // height: 42px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 20px;
      line-height: 42px;
      a {
        font-size: 14px;
        color: #cda156;
      }
      i {
        font-size: 14px;
        color: #cda156;
      }
    }
  }
  .choose-line {
    margin: 20px 0 20px 90px;
    width: 370px;
    border: 1px solid #d8d8d8;
  }
}
</style>
